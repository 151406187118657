export interface translatedTexts {
    welcome_title: string;
    welcome_body: string;
    best_regards: string;
    more_info: string;
    consent: string;
    privacy_policy: string;
    privacy_policy_url: string;
    privacy_policy_url_text: string;
    more_info_why_consent: string;
    more_info_channels: string;
    more_info_optout: string;
    confirmation_text: string;
    error: string;
    required_text: string;
    old_link: string;
    label_submit: string;
    label_firstname: string;
    label_lastname: string;
    label_site: string;
    label_primary_email: string;
    label_second_email: string;
    label_mobile: string;
    label_phone?: string;
    label_why_consent: string;
    label_channels: string;
    label_optout: string;
    label_close: string;
    label_specialty: string;
    label_other_specialty?: string;
    list_specialties?: string[];
    specialty_other?: string;
}
export const TranslatedText = (country: number = 0, responsible: string = ''): translatedTexts => {
    switch (country) {
        case 2:
            return ({
                welcome_title: 'Hei',
                welcome_body: 'Her samtykker du i å motta medisinsk informasjon og produktinformasjon/markedsføring av våre legemidler, samt invitasjoner til faglige møter og arrangementer.',
                best_regards: 'Med Vennlig hilsen',
                more_info: 'Mer informasjon om ditt samtykke',
                consent: 'Jeg bekrefter at dere har mitt samtykke til å kontakte meg via de nevnte kanaler og kjenner til at jeg når som helst kan trekke mitt samtykke tilbake.',
                privacy_policy: 'Jeg bekrefter at jeg har lest og forstått Tillotts ',
                privacy_policy_url: 'https://www.tillotts.se/integritetspolicy',
                privacy_policy_url_text:'Privacy Policy',
                label_why_consent: 'Hvorfor trenger vi ditt samtykke?',
                required_text: '',
                more_info_why_consent: ' Tillotts Pharma AG med filialer (kalt “Tillotts”) ønsker å nå deg med markeds/medisinsk kommunikasjon eller annen elektronisk kommunikasjon som inneholder informasjon om våre tjenester, produkter eller arrangementer relatert til din medisinske interesse (“Elektronisk markedsføring/Medisinsk kommunikasjon”).  For å kunne møte dine behov og preferanser kan det være nødvendig å analysere din bruk av Tillotts elektroniske kommunikasjon. Med anledning  i "The General Data Protection Regulation" (GDPR) trenger vi ditt aktive samtykke for å kunne tilby/sende deg ovenstående informasjon elektronisk. Ved å fylle ut dette skjemaet, gir du samtykke til å motta elektronisk markedsføring/medisinsk kommunikasjon fra Tillotts.',
                label_channels: 'Hvilke kanaler kommer vi til å bruke?',
                more_info_channels: 'Vi ber herved om din tillatelse til å kontakte deg via de foreskjellige kanaler med henblikk på elektronisk markedsføring, invitasjon til faglige arrangementer, medisinsk informasjon og produktinformasjon.',
                label_optout: 'Hvordan trekker jeg mitt samtykke tilbake?',
                more_info_optout: 'Du har naturligvis til enhver tid mulighet for å trekke ditt samtykke tilbake, samt endre de valgte kommunikasjonskanaler med umiddelbar virkning. Hvis du trekker ditt samtykke tilbake vil du ikke lenger motta elektroniske henvendelser om våre produkter, faglige arrangementer eller andre tjenester fra Tillotts via de valgte kanalene basert på dette samtykke. For å trekke ditt samtykke tilbake eller endre de valgte kommunikasjonskanalene kan du kontakte nordicinfo@tillotts.com.',
                confirmation_text: 'Takk for ditt samtykke, det kommer en bekreftelse fra meg innen kort tid.',
                error: 'Oj, något gick visst fel när ditt samtycke skulle sparas, har du möjlighet att pröva igen om några minuter?!',
                old_link: 'Oi, noe gikk visst feil. Det virker som om har brukt en gammel link. Kontakte meg så får du en ny link.',
                label_primary_email: 'Primær e-postadresse',
                label_second_email: 'Sekundær e-postadresse',
                label_mobile: 'Mobilnummer',
                label_submit: 'Send inn',
                label_close: 'Lukk',
                label_firstname: 'Fornavn',
                label_lastname: 'Etternavn',
                label_site: 'Arbeidsplass',
                label_specialty: 'Spesialitet',
                list_specialties: ['Allmennmedisin', 'Infeksjonssykdommer', 'Indremedisin', 'Klinisk mikrobiologi', 'Medisinsk gastroenterologi og hepatologi', 'Annen spesialitet'],
                specialty_other: 'Annen spesialitet',
                label_other_specialty: 'Annen spesialitet'

            })
        case 3:
            return ({
                welcome_title: 'Hej',
                welcome_body: 'Her kan du give du dit samtykke til at modtage medicinsk information og produktinformation / markedsføring om vores lægemidler, samt tilbud om faglige møder og indbydelser til undervisning mm.',
                best_regards: 'Med venlig hilsen',
                more_info: 'Flere oplysninger om dit samtykke',
                consent: 'Jeg bekræfter hermed at Tillotts har mit samtykke til at kontakte mig via de ovenstående kanaler og jeg er vidende om, at jeg til enhver tid kan trække mit samtykke tilbage',
                privacy_policy: 'Jeg bekræfter hermed, at jeg har læst og forstået Tillotts ',
                privacy_policy_url: 'https://www.tillotts.se/integritetspolicy',
                privacy_policy_url_text:'Privacy policy',
                required_text: '',
                label_why_consent: 'Hvorfor har vi brug for dit samtykke?',
                more_info_why_consent: 'Tillotts Pharma AB vil gerne sende medicinsk – og relevant produktinformation omkring vores lægemidler elektronisk. Det kan være information om f.eks. service, produkter, leveringsstatus eller events / uddannelsesaktiviteter relateret til dit medicinske arbejdsområde. I forhold til GDPR  ( The General Data Protection Regulation ) behøver vi dit samtykke til dette. Når / hvis du samtykker vil vi kunne analysere, hvordan du bruger vores udsendte information og dermed tilpasse dette til dine behov og ønsker fremadrettet.',
                label_channels: 'Hvilke kanaler vil vi bruge?',
                more_info_channels: 'Vi beder hermed om din tilladelse til at kontakte dig via e-mail og mobil / tlf evt. SMS med henblik på medicinsk information, produktinformation / markedsføring om vores lægemidler, samt tilbud om faglige møder og indbydelser til undervisning mm.',
                label_optout: 'Hvordan trækker jeg mit samtykke tilbage?',
                more_info_optout: 'Du har naturligvis til enhver tid mulighed for at trække dit samtykke tilbage samt ændre de valgte kommunikationskanaler med umiddelbar virkning. Hvis du trækker dit samtykke tilbage modtager du ikke længere elektronisk henvendelse omkring vore produkter eller events/uddannelsesarrangementer, som kommer fra Tillotts AB via de fravalgte kommunikationskanaler baseret på dit samtykke. For at trække dit samtykke tilbage eller ændre de valgte kommunikationskanaler kan du kontakte nordicinfo@tillotts.com, ligesom det ved enhver henvendelse vil være muligt at stoppe samtykket..\n',
                confirmation_text: 'Tak for dit samtykke. Der vil snart komme en bekræftelse til dig på angivne e-mail',
                error: 'Oj, något gick visst fel när ditt samtycke skulle sparas, har du möjlighet att pröva igen om några minuter?!',
                old_link: 'Hovsa, Noget gik galt. Du har anvendt en gammel link. Kontakt mig for at få en ny link.',
                label_primary_email: 'Primære e-mail adresse',
                label_second_email: 'Sekundær e-mail adresse',
                label_phone: 'Dit arbejds telefon nummer',
                label_mobile: 'Mobilnummer',
                label_submit: 'Indsend',
                label_close: 'Luk',
                label_firstname: 'Fornavn',
                label_lastname: 'Efternavn',
                label_site: 'Arbejdsplads',
                label_specialty: 'Speciale',
                list_specialties: ['Almen medicin', 'Infektionssygdomme', 'Intern medicin', 'Klinisk mikrobiologi', 'Medicinsk gastroenterologi og hepatologi', 'Anden specialitet'],
                specialty_other: 'Anden specialitet',
                label_other_specialty: 'Anden specialitet'

            })
        case 4:
            return ({
                welcome_title: 'Hyvä',
                welcome_body: 'Tillotts Pharma AB haluaisi lähettää sinulle sähköistä markkinointi ja/tai lääketieteellistä viestintää, joka sisältää tietoa mm. palveluistamme, tuotteistamme tai koulutustapahtumista. Viestinnän sisältö voi liittyä myös mahdollisiin tuotteiden toimitusvaikeuksiin tai tuotteisiin littyvään uuteen tietoon \n' +
                    'Joista haluaisimme välittää sinulle tiedon nopeasti. \n' +
                    'Ilmoittamalla suostumuksesi suostut vastaanottamaan Tillotts Pharmalta sähköistä tiedonvälitystä ja voit antaa myös henkilökohtaisia yhteystietojasi tapaamisia/webinaareja/kongresseja varten.\n',
                best_regards: 'Ystävällisin terveisin',
                more_info: 'Lisätietoa suostumuspyynnöstä',
                consent: 'Vahvistan suostumukseni sille, että Tillotts Pharma voi lähettää minulle mainittujen sähköisten kanavien kautta tietoa ja voin peruuttaa suostumukseni milloin tahansa.',
                privacy_policy: 'Vahvistan lukeneeni ja ymmärtäneeni Tillotts Pharman tietosuojailmoituksen, joka on saatavilla osoitteessa ',
                privacy_policy_url: 'https://www.tillotts.com/privacy-policy',
                privacy_policy_url_text:'tillotts.com/privacy-policy.',
                required_text: '',
                more_info_why_consent: 'Myös etänä toteutettaviin lääke-esittelyihin ja koulutuksiin pätevät samat eettiset ohjeet ja säännöt, kuin muulloinkin. Tällöin on keskeistä, että esittelyyn/tapahtumaan osallistuvilta on saatu suostumus sähköiseen viestintään. Lisäksi sähköistä viestintää saa lähtökohtaisesti kohdistaa vain henkilöille, jotka ovat antaneet siihen suostumuksen etukäteen. Mikäli sinulla on kysymyksiä liittyen suostumuspyyntöömme, vastaan mielelläni sähköpostitse toni.aitkoski@tillotts.com. Tarkempaa tietoa eri tilanteisiin saa tietosuojavaltuutetun sivuilta: https//tietosuoja.fi/usein-kysyttya-suoramarkkinointi.',
                more_info_channels: 'Pyydämme suostumustasi edellä mainittujen kanavien kautta yhteydenottoon, jotta voimme lähettää sähköistä markkinointi- ja/tailääketieteellistä viestintää.',
                more_info_optout: 'Voit vapaasti peruuttaa suostumuksesi tai muuttaa valittuja viestintäkanavia milloin tahansa. Katso lisätietoja tietosuojailmoituksestamme osoitteessa www.tillotts.com/privacy-policy. Peruutuksen jälkeen et enää saa Tillotts Pharmalta sähköistä markkinointi- ja/tai lääketieteellistä viestintää suostumuksessa mainittujen kanavien kautta.\n' +
                    '\n' +
                    'Mikäli haluat peruuttaa suostumuksesi tai muuttaa valittuja viestintäkanavia, ota yhteyttä nordicinfo@tillotts.com \n',
                confirmation_text: 'Kiitos suostumuksestasi - tulet saamaan hetken kuluttua vahvistusviestin sähköpostiisi.',
                error: 'Oj, något gick visst fel när ditt samtycke skulle sparas, har du möjlighet att pröva igen om några minuter?!',
                old_link: 'Jotain meni väärin, olet mahdollisesti käyttänyt vanhaa linkkiä. Ota yhteyttä minuun, niin laitan uuden linkin!',
                label_primary_email: 'Ensisijainen sähköposti',
                label_second_email: 'Toissijainen sähköposti',
                label_mobile: 'Matkapuhelin',
                label_submit: 'Lähetä suostumus',
                label_channels: 'Viestintäkanavat: sähköposti, puhelin, matkapuhelin (tekstiviesti ja viestipalvelut, esim. Messenger, WhatsApp)',
                label_close: 'Sulje ikkuna',
                label_optout: 'Suostumuksen peruutus?',
                label_why_consent: 'Miksi pyydämme suostumustasi?',
                label_firstname: 'Etunimi',
                label_lastname: 'Sukunimi',
                label_site: 'Työpaikka',
                label_specialty: 'Erikoisala',
                list_specialties: ['Yleislääketiede', 'Infektiosairaudet', 'Sisätaudit', 'Kliininen mikrobiologia', 'Lääketieteellinen gastroenterologia ja hepatologia', 'Muu erikoisala'],
                specialty_other: 'Muu erikoisala',
                label_other_specialty: 'Muu erikoisala'
            })
        case 1:
            return ({
                welcome_title: 'Hej',
                welcome_body: 'Här kan du lämna ditt samtycke till att ta emot medicinsk information, produktinformation om våra läkemedel och erbjudanden om utbildningar, tjänster etc',
                best_regards: 'Med vänlig hälsning',
                more_info: 'Mer information om ditt samtycke',
                consent: 'Jag bekräftar att ni har mitt samtycke att kontakta mig via de ovan angivna kanalerna och jag förstår att jag kan dra tillbaka mitt samtycke när som helst.',
                privacy_policy: 'Jag bekräftar att jag har läst och förstått Tillotts ',
                privacy_policy_url: 'https://www.tillotts.se/integritetspolicy/integritetspolicy-for-for-halso-och-sjukvardspersonal/',
                privacy_policy_url_text: 'integritetspolicy',
                more_info_why_consent: 'Tillotts Pharma AB (”Tillotts”, ”vi”) önskar skicka medicinsk information och produktinformation kring våra läkemedel samt information om t.ex. tjänster, material och evenemang/utbildningar relaterat till ditt medicinska intresse, elektroniskt direkt till dig. ' +
                    'Med anledning av Dataskyddsförordningen (The General Data Protection Regulation, GDPR) behövs ditt aktiva samtycke för att kunna erbjuda dig/skicka dig ovan nämnda information elektroniskt. ' +
                    'När/om du samtycker kommer vi analysera hur du använder våra utskick, för att kunna göra dem så relevanta som möjligt. ' +
                    'Genom att ge ditt samtycke godkänner du att Tillotts får skicka material med medicinsk information/produktinformation etc elektroniskt till dig. ' +
                    'Då vi har skyldighet att se till att de personuppgifter vi behandlar är aktuella kommer de ändringar vi får kännedom om, gällande dina uppgifter, uppdateras i våra system.',
                more_info_channels: ' Vi ber om din tillåtelse att få kontakta dig via e-post och, om du angivit mobiltelefonnummer, även mobiltelefon (via SMS och meddelandetjänster, t.ex. WhatsApp ) för att elektroniskt kunna förse dig med medicinsk information /produktinformation /inbjudningar etc',
                more_info_optout: 'Du har rätt att ta tillbaka ditt samtycke eller ändra de valda kommunikationskanalerna när som helst med framtida verkan.\n' +
                    'Om du tar tillbaka ditt samtycke kommer du inte längre kunna få elektroniskt material med medicinsk information/produktinformation eller information om evenemang/utbildningar eller tjänster från Tillotts via de valda kanalerna baserat på detta samtycke.\n' +
                    'För att ta tillbaka ditt samtycke eller ändra de valda kommunikationskanalerna kontakta:\n' +
                    'nordicinfo@tillotts.com',
                confirmation_text: 'Tack för att du lämnar ditt samtycke. Inom kort kommer en bekräftelse till din angivna e-post.',
                error: 'Oj, något gick visst fel när ditt samtycke skulle sparas, har du möjlighet att pröva igen om några minuter?!',
                old_link: 'Oj, något gick visst fel. Du verkar ha använt en gammal länk. Kontakta mig så får du en ny länk!',
                label_primary_email: 'Primär e-postadress',
                label_second_email: 'Alternativ e-postadress',
                label_mobile: 'Mobilnummer',
                required_text: '*obligatorisk uppgift',
                label_submit: 'SKICKA IN',
                label_channels: 'Vilka kanaler kommer vi använda?',
                label_close: 'STÄNG',
                label_optout: 'Hur tar jag tillbaka mitt samtycke?',
                label_why_consent: 'Varför behöver vi ditt samtycke?',
                label_firstname: 'Förnamn',
                label_lastname: 'Efternamn',
                label_site: 'Arbetsplats',
                label_specialty: 'Specialitet',
                list_specialties: ['Allmänmedicin', 'Infektionssjukdomar', 'Internmedicin', 'Klinisk mikrobiologi', 'Medicinsk gastroenterologi och hepatologi', 'Annan specialitet'],
                specialty_other: 'Annan specialitet',
                label_other_specialty: 'Annan specialitet'
            })
        default:
        return ({
            welcome_title: '',
            welcome_body: '',
            best_regards: 'Best regards',
            more_info: '',
            consent: '',
            required_text: '',
            privacy_policy: '',
            privacy_policy_url: '',
            privacy_policy_url_text:'',
            more_info_why_consent:'',
            more_info_channels: '',
            more_info_optout: '',
            confirmation_text: '',
            error: '',
            old_link: 'Ooops, something went wrong. Please get back to Tillotts and ask for a new link',
            label_submit: '',
            label_primary_email: '',
            label_second_email: '',
            label_mobile: '',
            label_phone: '',
            label_why_consent: '',
            label_channels: '',
            label_optout: '',
            label_close: '',
            label_firstname: 'First name',
            label_lastname: 'Last name',
            label_site: 'Site',
            label_specialty: 'Specialty',
            specialty_other: 'Other specialty',
            label_other_specialty: 'Other specialty',
            list_specialties: ['']
        })
    }


}
